<template>
  <div id="payment" class="py-4">
    <div
      v-if="dataPayment.tx_value"
      style="background-color: white"
      class="col-11 col-md-10 mx-auto mt-3 text-center"
    >
      <div class="container-resumen-transaccion">
        <div
          v-if="dataPayment.status_id == 1"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #c6ecbb">
            <img src="@/assets/imgs/estado-success.svg" width="33" />
          </div>
          <h3 class="w-100"><b>Transacción exitosa</b></h3>
          <p class="w-100">Se ha registrado tu pago</p>
        </div>
        <div
          v-if="dataPayment.status_id == 2"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #ffc8c8">
            <img src="@/assets/imgs/estado-error.svg" width="33" />
          </div>
          <h3 class="w-100"><b>Transacción rechazada</b></h3>
          <p class="w-100">No se ha podido validar tu pago</p>
        </div>
        <div
          v-if="dataPayment.status_id == 0"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #ffe7cb">
            <img src="@/assets/imgs/estado-alert.svg" width="33" />
          </div>
          <h3 class="w-100"><b>Transacción Pendiente</b></h3>
          <p class="w-100">La transacción esta en proceso</p>
        </div>

        <div class="w-100 d-flex flex-wrap">
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Referencia de la venta</b> <br />
              {{ dataPayment.referenceCode }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Referencia de la transacción</b> <br />
              {{ dataPayment.transactionId }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Fecha</b> <br />
              {{ dataPayment.fecha }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Valor total</b> <br />
              ${{ dataPayment.tx_value }} {{ dataPayment.currency }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Descripción</b> <br />
              {{ dataPayment.description }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Entidad</b> <br />
              {{ dataPayment.paymentMethod }}
            </p>
          </div>
        </div>
        <div class="w-100">
          <div>
            <b-button
              v-if="dataPayment.urlPayment != ''"
              :href="dataPayment.urlPayment"
              class="col-12 col-md-4 col-xl-3 mt-3 text-white text-decoration-none"
              variant="primary"
            >
              Ver referencia de pago
            </b-button>
          </div>
        </div>
        <div class="w-100">
          <p class="pt-4 mb-0 text-start fs-14">
            <b>Nota: </b>
            Esta página de resumen no implica aceptación del pago, el resultado
            de esta transacción será validado por <b>CLOVERBOLSOS.COM</b> con
            los datos suministrados en la plataforma de payU.
          </p>
        </div>
      </div>
    </div>
    <notification-summary-payment
      v-if="showmodal"
      :info-notification="infoNotification"
      @closeModal="showmodal = false"
    />
  </div>
</template>
<script>
import NotificationSummaryPayment from '@/components/Templates/NotificationSummaryPayment'
export default {
  name: 'PaymentQuotaDetail',
  components: { NotificationSummaryPayment },
  data() {
    return {
      routerBreadcrumb: [{ text: 'Resumen transacción', active: true }],
      dataPayment: {
        tx_value: '-',
        status_id: 0,
        referenceCode: '-',
        transactionId: '-',
        fecha: '-',
        currency: '-',
        description: '-',
        paymentMethod: '-',
      },
      infoNotification: null,
      showmodal: false,
    }
  },
  mounted() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover-simple'
    this.$store.state.layouts.menuLayoutClover = 'menu-layout-simple'
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
  async created() {
    let query = this.$route.query
    this.dataPayment.tx_value = query.TX_VALUE
      ? new Intl.NumberFormat('de-DE').format(parseInt(query.TX_VALUE))
      : '-'
    this.dataPayment.referenceCode = query.referenceCode
      ? query.referenceCode
      : '-'
    this.dataPayment.transactionId = query.transactionId
      ? query.transactionId
      : '-'
    this.dataPayment.fecha = query.processingDate ? query.processingDate : '-'
    this.dataPayment.currency = query.currency ? query.currency : '-'
    this.dataPayment.description = query.description ? query.description : '-'
    this.dataPayment.paymentMethod = query.lapPaymentMethod
      ? query.lapPaymentMethod + (query.pseBank ? `- ${query.pseBank}` : '')
      : '-'
    switch (query.lapTransactionState) {
      case 'APPROVED':
        this.dataPayment.status = 'Transacción aprobada'
        this.dataPayment.status_id = 1
        break
      case 'DECLINED':
        this.dataPayment.status = 'Transacción rechazada'
        this.dataPayment.status_id = 2
        break
      case 'ERROR':
        this.dataPayment.status = 'Error procesando la transacción'
        this.dataPayment.status_id = 2
        break
      case 'EXPIRED':
        this.dataPayment.status = 'Transacción expirada'
        this.dataPayment.status_id = 2
        break
      case 'PENDING':
        this.dataPayment.status = 'Transacción pendiente o en validación'
        this.dataPayment.status_id = 0
        break
      case 'SUBMITTED':
        this.dataPayment.status =
          'Transacción enviada a la entidad financiera y por algún motivo no terminó su procesamiento'
        this.dataPayment.status_id = 2
        break
      default:
        this.dataPayment.status = ''
        this.dataPayment.status_id = 2
        break
    }
    if (query.lapTransactionState == 'APPROVED') {
      let data = new FormData()
      data.append('referenceCode', query.referenceCode)
      this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/cupopersonal/paymentFee/getDataTransaction',
        data,
      }).then(response => {
        this.infoNotification = response.data.order.cuponEstrategiaReventa
        this.showmodal = true
      })
    }
  },
}
</script>
<style lang="scss">
.container-resumen-transaccion {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  padding-top: 0;
  margin-top: 4rem;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
}
.container-resumen-transaccion .estado-transaccion {
  border-radius: 50%;
  min-height: 70px;
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: -35px;
  margin-bottom: 1rem;
}
.container-resumen-transaccion .item-transaccion {
  padding: 0.5rem;
}
.container-resumen-transaccion .item-transaccion p {
  width: 100%;
  background-color: #f7f3f0;
  margin-bottom: 0;
  text-align: center;
  padding: 1.5rem 1rem;
  border-radius: 10px;
}
</style>
